import Vue from 'vue/dist/vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import store from '@scripts/store';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { showErrorMessage, showWarningMessage } from '@library/uikit/notifications';
import { form } from '@scripts/mixins/form';
import { mapDate, mapRequestCodeResponse } from '@scripts/mappings';
import { VUEX_GETTERS, VALIDATION_MESSAGES, VALIDATION_RULES, MESSAGES, VUEX_MUTATIONS } from '@scripts/constants';
import { INPUT_MASKS } from '@library/scripts/constants';
import { DebounceLoading } from '@library/forms/debounce-loading';
import {
    apiChangePhone,
    apiChangePhoneRequestCode,
    apiRequestCode,
    apiRequestCodeEmail,
    apiOrdersCalculate,
    apiOrdersCreate,
    apiCreateUserAddress,
    apiGetCountries,
    apiGetStreets,
    apiGetCities,
    apiGetDeliveryOptions,
    apiLogin,
    apiLoginByEmail,
    apiApplyDiscountToCart,
    apiReleaseDiscountToCart,
} from '@scripts/api-methods';
import { reduceStocks } from '@scripts/helpers';
import { debounce } from 'lodash-es';
import Dropdown from '../../components/dropdown/dropdown.vue';
import CheckoutItemsList from '../../components/checkout-items-list/checkout-items-list.vue';
import UIkit from '../../vendor/uikit/uikit';
import { yaEcommerce, carrotquestTrack } from '@scripts/counters';

import VueSuggestions from 'vue-suggestions';

const SHIPPING_TYPES = {
    PICKUP: 'pickup',
    DELIVERY: 'delivery',
};

const PAYMENT_TYPES = {
    OFFLINE: 'upon_receipt',
    ONLINE: 'online',
    ONLINE_YOO: 'online_yoo',
    ACCOUNT_NUMBER: 'wire',
};

const userAddressScheme = () => ({
    country: 'Россия',
    city: null,
    street: null,
    postalCode: '',
    building: '',
    flat: '',
});

const TABS_AUTH = {
    EMAIL: 'email',
    PHONE: 'phone',
};
//--------------
const pickupDefaultAddressesId = document.getElementsByClassName('checkout-shippingpickup-address-id');
const userDefaultAddressesId = document.getElementsByClassName('checkout-shippinguser-address-id');
//--------------

const shippingInfo = () => ({
    type: SHIPPING_TYPES.PICKUP,
    deliveryTypeAvailable: null,
    deliveryTypeOnOrder: null,
    deliveryOfficeAvailable: null,
    deliveryOfficeOnOrder: null,
    pickupAddressId: (pickupDefaultAddressesId !== undefined && pickupDefaultAddressesId[0] !== undefined)?pickupDefaultAddressesId[0].getAttribute('data-value'): null,//null,  //сюда надо передать id склада (первого из списка)
    userAddressId: (userDefaultAddressesId !== undefined && userDefaultAddressesId[0] !== undefined)?userDefaultAddressesId[0].getAttribute('data-value'):  null,
    userAddress: userAddressScheme(),
});

const formDataScheme = () => ({
    auth: {
        phone: '',
        code: '',
        email: '',
    },
    email: '',
    name: '',
    requiresManagerCall: false,
    shipping: shippingInfo(),
    additionalShipping: shippingInfo(),

    payment: {
        // type: PAYMENT_TYPES.ONLINE,
        type: PAYMENT_TYPES.ONLINE_YOO,
        account: {
            organizationName: '',
            inn: '',
            kpp: '',
            rs: '',
            name: '',
            phone: '',
            contacts: ''
        },
    },
    orderPhone: '',
    comment: '',
    needManagerCall: 'no',
});

let globalSuggestion = {};

export default (el, name, data) =>
    new Vue({
        el,
        name,
        store,
        components: {
            CheckoutItemsList,
            Dropdown,
            VueSuggestions,
        },
        data: () => ({
            roleData: data.roleData,
            warehouses: data.warehouses,
            cartNotificationCity: '',
            inStockCart: [],
            outOfStockCart: [],
            total: 0,
            priceProducts: 0,
            shippingPrice: 0,
            outOfStockProductsPrice: 0,
            inStockProductsPrice: 0,
            deliveryTime: 0,
            deliveryTimeString: '',
            deliveryTimeShipment: '',
            submitCodeLoader: new DebounceLoading(),
            submitCodeDelay: 0,
            submitCodeDelayTimer: undefined,
            submitButtonLoading: false,
            showInputCodeStep: false,
            showInputCodeStepEmail: false,
            formData: formDataScheme(),
            deliveryOptions: null,
            deliveryOffices: null,
            SHIPPING_TYPES,
            PAYMENT_TYPES,
            masks: {
                phone: INPUT_MASKS.PHONE_MASK,
                phone_free: INPUT_MASKS.PHONE_FREE_MASK,
            },
            dropdownOptions: {
                countries: [],
                cities: [],
                streets: [],
            },
            cartTotalStickyElement: '[data-sticky-cart-total]',
            isAddingAddress: false,
            calculateLoading: false,
            isDeliveryCalculated: true,
            isPaymentUponReceipt: false,
            city: '',
            coordinates: {
                latitude: '',
                longitude: ''
            },
            currentTabAuth: TABS_AUTH.PHONE,
            discountCode: '',
            discountAmount: 0,
            totalCostWithoutDiscount: 0,
            suggestionOptions: {
                // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
                token: '866fa4f40acd4756d401d382370292364c25757d',
                type: "ADDRESS",
                scrollOnFocus: false,
                triggerSelectOnBlur: false,
                triggerSelectOnEnter: true,
                addon: 'none',
                // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
                onSelect (suggestion) {
                    // this.setDataFromSuggestion(suggestion);
                    if (suggestion.data && suggestion.data !== undefined){
                        globalSuggestion = suggestion;
                        // globalSuggestion.suggestion = suggestion;
                    }
                }
            },
            TABS_AUTH,
        }),
        computed: {
            ...mapGetters([VUEX_GETTERS.CART_TOTAL_ITEMS_QUANTITY]),
            ...mapState(['cart', 'selectedCity', 'user']),
            hasOutOfStock() {
                return this.outOfStockCart.length > 0;
            },
            hasInStock() {
                return this.inStockCart.length > 0;
            },
            hasPhone() {
                return this.user && this.user.isLoggedIn && (this.user.phone || this.user.email);
            },
            isUserWholesaler() {
                return this.user.type === 'wholesaler';
            },
            addressFormFilled() {
                return (
                    Boolean(this.formData.shipping.userAddress.country &&
                        this.formData.shipping.userAddress.flat &&
                        this.formData.shipping.userAddress.city &&
                        this.formData.shipping.userAddress.street &&
                        this.formData.shipping.userAddress.building &&
                        this.formData.shipping.userAddress.postalCode )
                );
            },
            formattedAddress() {
                let glData = (globalSuggestion.data !== undefined) ? globalSuggestion.data : null;
                if (glData){
                    glData.city = (glData.city !== undefined) ? glData.city : null;
                    if (!glData.city){
                        glData.city = (glData.settlement !== undefined) ? glData.settlement : null;
                    }
                    glData.city_fias_id = (glData.city_fias_id !== undefined) ? glData.city_fias_id : null;
                }else{
                    glData = {
                        city: null,
                        city_fias_id: null,
                    }
                }

                return {
                    country: this.formData.shipping.userAddress.country,

                    city: this.formData.shipping.userAddress.city.label ?? glData.city,
                    city_fias_id: this.formData.shipping.userAddress.city.value ?? glData.city_fias_id,

                    street: this.formData.shipping.userAddress.street.label ?? this.formData.shipping.userAddress.street,
                    building: this.formData.shipping.userAddress.building,
                    flat: this.formData.shipping.userAddress.flat || null,
                    postal_code: this.formData.shipping.userAddress.postalCode,
                    latitude: (this.formData.shipping.userAddress.street.value)?this.formData.shipping.userAddress.street.value.latitude:0,
                    longitude: (this.formData.shipping.userAddress.street.value)?this.formData.shipping.userAddress.street.value.longitude:0,
                    user_address_id: this.formData.shipping.userAddress.id,
                };
            },
        },
        mixins: [
            form(),
            formSending({ useRecaptcha: true }),
            formValidation(
                {
                    phone: {
                        field: 'formData.auth.phone',
                        rules: {
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    new_phone: {
                        field: 'formData.auth.phone',
                        rules: {
                            ...VALIDATION_RULES.PHONE,
                        },
                    },
                    email: {
                        field: 'formData.auth.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL
                        },
                    },
                    free_phone: {
                        field: 'formData.orderPhone',
                        rules: {
                            ...VALIDATION_RULES.PHONE_FREE
                        },
                    },
                },
                VALIDATION_MESSAGES
            ),
        ],
        beforeMount() {
            if (this.user.isLoggedIn && this.user.phone) {
                this.formData.auth = null;
                this.formData.orderPhone = this.user.phone;
            }

            if (this.user.name) {
                this.formData.name = this.user.name;
            }

            if (this.user.email) {
                this.formData.email = this.user.email;
            }
            this.setCartPrice();
            this.calculateOrder();
            carrotquestTrack('Оформить заказ');
        },
        methods: {
            ...mapMutations([VUEX_MUTATIONS.SET_USER_PHONE]),

            setCartPrice() {
                if (this.isUserWholesaler) {
                    this.total = this.cart.totalPriceWholesale;
                    this.priceProducts = this.cart.totalPriceWholesale;
                } else {
                    this.total = this.cart.totalPriceRetail;
                    this.priceProducts = this.cart.totalPriceRetail;
                }
            },

            addAddressClick() {
                this.isAddingAddress = !this.isAddingAddress;

                if (this.isAddingAddress) {
                    this.formData.shipping.userAddressId = null;
                }
            },

            selectFirstCountry() {
                if (!this.formData.shipping.userAddress.country) [this.formData.shipping.userAddress.country] = this.dropdownOptions.countries;
            },

            selectFirstCity() {
                if (!this.formData.shipping.userAddress.city) [this.formData.shipping.userAddress.city] = this.dropdownOptions.cities;
            },

            selectFirstStreet() {
                if (!this.formData.shipping.userAddress.street) [this.formData.shipping.userAddress.street] = this.dropdownOptions.streets;
            },

            loadCountries(settings) {
                if (settings.query && settings.query.length > 0) {
                    settings.setLoading(true);

                    this.sendForm(
                        () =>
                            apiGetCountries({
                                query: settings.query,
                                results_count: 4,
                                captcha_token: this.token,
                            }),
                        (result) => {
                            this.dropdownOptions.countries = result.items.map((item) => item.title);
                        },
                        () => {
                            showErrorMessage({
                                text: MESSAGES.COUNTRIES_LOAD_ERROR,
                            });
                        },
                        {
                            useValidation: false,
                        }
                    ).finally(() => settings.setLoading(false));
                }
            },

            loadCities(settings) {
                if (settings.query && settings.query.length > 0 && this.formData.shipping.userAddress.country) {
                    settings.setLoading(true);

                    this.sendForm(
                        () =>
                            apiGetCities({
                                query: settings.query,
                                results_count: 4,
                                country: this.formData.shipping.userAddress.country,
                                captcha_token: this.token,
                            }),
                        (result) => {
                            this.dropdownOptions.cities = result.items.map((item) => ({
                                label: item.title,
                                value: item.fias_id,
                            }));
                        },
                        () => {
                            showErrorMessage({
                                text: MESSAGES.CITIES_LOAD_ERROR,
                            });
                        },
                        {
                            useValidation: false,
                        }
                    ).finally(() => settings.setLoading(false));
                }
            },

            loadStreets(settings) {
                if (settings.query && settings.query.length > 0 && this.formData.shipping.userAddress.country && this.formData.shipping.userAddress.city) {
                    settings.setLoading(true);

                    this.sendForm(
                        () =>
                            apiGetStreets({
                                query: settings.query,
                                results_count: 4,
                                city_fias_id: this.formData.shipping.userAddress.city.value,
                                captcha_token: this.token,
                            }),
                        (result) => {
                            this.dropdownOptions.streets = result.items.map((item) => ({
                                label: item.title,
                                value: item,
                            }));
                        },
                        () => {
                            showErrorMessage({
                                text: MESSAGES.STREETS_LOAD_ERROR,
                            });
                        },
                        {
                            useValidation: false,
                        }
                    ).finally(() => settings.setLoading(false));
                }
            },

            async createUserAddress(address) {
                await apiCreateUserAddress(address);
            },

            getCode() {
                if (this.submitCodeDelay > 0) {
                    return null;
                }

                let apiMethodCall = () => apiRequestCode({ phone: this.formData.auth.phone, captcha_token: this.token });

                if (this.user.isLoggedIn) {
                    apiMethodCall = () => apiChangePhoneRequestCode({ new_phone: this.formData.auth.phone, captcha_token: this.token });
                }

                return this.sendForm(
                    () => apiMethodCall().then((result) => mapRequestCodeResponse(result)),
                    (result) => {
                        this.showInputCodeStep = true;
                        this.startSubmitCodeDelayTimer(result.resendDelay);
                    },
                    null,
                    {
                        debounceLoading: this.submitCodeLoader,
                        scrollToFirstInvalidField: false,
                    }
                );
            },
//-------------------------------
            getCodeEmail() {
                if (this.submitCodeDelay > 0) {
                    return null;
                }

                let apiMethodCall = () => apiRequestCodeEmail({ email: this.formData.auth.email, captcha_token: this.token });

                // if (this.user.isLoggedIn) {
                //     apiMethodCall = () => apiChangePhoneRequestCode({ new_phone: this.formData.auth.phone, captcha_token: this.token });
                // }

                return this.sendForm(
                    () => apiMethodCall().then((result) => mapRequestCodeResponse(result)),
                    (result) => {
                        this.showInputCodeStepEmail = true;
                        this.startSubmitCodeDelayTimer(result.resendDelay);
                    },
                    null,
                    {
                        debounceLoading: this.submitCodeLoader,
                        scrollToFirstInvalidField: false,
                    }
                );
            },
            sendCodeEmail() {
                let apiMethodCall = () =>
                    apiLoginByEmail({
                        code: this.formData.auth.code,
                        email: this.formData.auth.email,
                    });

                return this.sendForm(
                    () => apiMethodCall(),
                    () => {
                        window.location.reload();
                    },
                    () => {
                        showErrorMessage({
                            text: 'Неверное значение',
                        });
                    },
                    {
                        scrollToFirstInvalidField: false,
                    }
                );
            },
//-------------------------------
            sendCode() {
                let apiMethodCall = () =>
                    apiLogin({
                        code: this.formData.auth.code,
                        phone: this.formData.auth.phone,
                    });

                if (this.user.isLoggedIn) {
                    apiMethodCall = () =>
                        apiChangePhone({
                            code: this.formData.auth.code,
                            new_phone: this.formData.auth.phone,
                        });
                }

                return this.sendForm(
                    () => apiMethodCall(),
                    () => {
                        if (this.user.isLoggedIn) {
                            this.setUserPhone(this.formData.auth.phone);
                        } else {
                            window.location.reload();
                        }
                    },
                    () => {
                        showErrorMessage({
                            text: 'Неверное значение',
                        });
                    },
                    {
                        scrollToFirstInvalidField: false,
                    }
                );
            },

            startSubmitCodeDelayTimer(delay) {
                this.submitCodeDelay = delay;
                this.submitCodeDelayTimer = setInterval(() => {
                    this.submitCodeDelay -= 1;
                    if (this.submitCodeDelay === 0) {
                        clearInterval(this.submitCodeDelayTimer);
                    }
                }, 1000);
            },

            removeDeliveryOptions() {
                this.deliveryOptions = null;
                this.formData.shipping.deliveryTypeAvailable = null;
                this.formData.shipping.deliveryTypeOnOrder = null;
                this.deliveryOffices = null;
            },

            //Получить пункты выдачи для выбранного способа доставки
            getDeliveryOfficesByTariffId(tariffId) {
                let tariffOffices = [];
                if (this.deliveryOffices && this.deliveryOffices.length > 0){
                    this.deliveryOffices.forEach( (office) => {
                        if(office.tariffIds && office.tariffIds.includes(tariffId)){
                            tariffOffices.push(office);
                        }
                    });
                }
                return tariffOffices;
            },

            //Включение способа оплаты для Владивостока
            checkPaymentOnReceipt(){
                this.isPaymentUponReceipt=false;
                //проверить склад Владивосток, и если да, то сделать доступным оплату при получении
                if (this.formData.shipping.type === SHIPPING_TYPES.PICKUP && this.formData.shipping.pickupAddressId === "6"){
                    this.isPaymentUponReceipt=true;
                }else if(this.formData.payment.type === PAYMENT_TYPES.OFFLINE){
                    //если была выбрана доставка при получении и изменился склад или способ доставки
                    this.formData.payment.type =  PAYMENT_TYPES.ONLINE;
                }

            },

            getDeliveryOptions: debounce(async function () {
                this.calculateLoading = true;

                let payload = null;

                if (this.formData.shipping.userAddressId) {
                    payload = { user_address_id: this.formData.shipping.userAddressId };
                } else if (this.addressFormFilled) {
                    payload = {
                        address: this.formattedAddress,
                    };
                }

                if (payload) {
                    try {
                        this.deliveryOptions = await apiGetDeliveryOptions(payload);

                        if (
                            this.deliveryOptions &&
                            this.deliveryOptions.available_part &&
                            this.deliveryOptions.available_part.tariffs &&
                            this.deliveryOptions.available_part.tariffs.length > 0
                        ) {
                            [this.formData.shipping.deliveryTypeAvailable] = this.deliveryOptions.available_part.tariffs;
                        }

                        if (
                            this.deliveryOptions &&
                            this.deliveryOptions.on_order_part &&
                            this.deliveryOptions.on_order_part.tariffs &&
                            this.deliveryOptions.on_order_part.tariffs.length > 0
                        ) {
                            [this.formData.shipping.deliveryTypeOnOrder] = this.deliveryOptions.on_order_part.tariffs;
                        }

                        //----Сохранить в память и использовать при расчете досатви, те при выборе способа доставки
                        //    и заполнять в соответсвии с id способа дставки------
                        if (
                            this.deliveryOptions &&
                            this.deliveryOptions.available_part &&
                            this.deliveryOptions.available_part.offices &&
                            this.deliveryOptions.available_part.offices.length > 0
                        ) {
                            this.deliveryOffices = this.deliveryOptions.available_part.offices;
                            this.deliveryOptions.available_part.offices = [];
                            // [this.formData.shipping.deliveryOfficeAvailable] = this.deliveryOptions.available_part.offices;
                        }

                        if (
                            this.deliveryOptions &&
                            this.deliveryOptions.on_order_part &&
                            this.deliveryOptions.on_order_part.offices &&
                            this.deliveryOptions.on_order_part.offices.length > 0
                        ) {
                            this.deliveryOffices = this.deliveryOptions.on_order_part.offices;
                            this.deliveryOptions.on_order_part.offices = [];
                            // [this.formData.shipping.deliveryOfficeOnOrder] = this.deliveryOptions.on_order_part.offices;
                        }
                        //----------------------
                        //Если доставка не рассчитана
                        if (
                            this.deliveryOptions &&
                            (!this.deliveryOptions.available_part  || this.deliveryOptions.available_part &&
                            this.deliveryOptions.available_part.tariffs &&
                            this.deliveryOptions.available_part.tariffs.length === 0) &&

                            (!this.deliveryOptions.on_order_part || this.deliveryOptions.on_order_part &&
                            this.deliveryOptions.on_order_part.tariffs &&
                            this.deliveryOptions.on_order_part.tariffs.length === 0)
                        ) {
                            this.isDeliveryCalculated = false;
                            this.formData.shipping.deliveryTypeAvailable = null;
                            this.formData.shipping.deliveryTypeOnOrder = null;
                            showWarningMessage({
                                text: 'Доставка не рассчитана. Укажите параметры доставки в комментарии.',
                            });
                        }else{
                            this.isDeliveryCalculated = true;
                        }
                        //----------------------

                    } catch {
                        this.deliveryOptions = null;

                        showErrorMessage({
                            text: 'Не удалось рассчитать доставку',
                        });
                    }

                    await this.calculateOrder();
                }

                this.calculateLoading = false;
            }, 400),

            async calculateOrder() {
                this.calculateLoading = true;
                let payload = null;

                //проверить склад Владивосток, и если да, то сделать доступным оплату при получении
                this.checkPaymentOnReceipt();

                if (this.formData.shipping.type === SHIPPING_TYPES.PICKUP) {

                    payload = {
                        delivery_destination: {
                            warehouse_id: this.formData.shipping.pickupAddressId,
                        },
                        delivery_parameters: null,
                        payment_method: this.formData.payment.type,
                    };
                } else if (this.formData.shipping.userAddressId) {
                    payload = {
                        delivery_destination: {
                            user_address_id: this.formData.shipping.userAddressId,
                        },
                        delivery_parameters: null,
                        payment_method: this.formData.payment.type,
                    };
                } else if (this.addressFormFilled) {
                    payload = {
                        delivery_destination: {
                            address: this.formattedAddress,
                        },
                        delivery_parameters: null,
                        payment_method: this.formData.payment.type,
                    };
                }

                if (payload) {
                    if (this.formData.shipping.type === SHIPPING_TYPES.DELIVERY && this.deliveryOptions) {
                        if (this.formData.shipping.deliveryTypeAvailable) {
                            payload.delivery_parameters = {};

                            payload.delivery_parameters.available_part = {
                                type: 'delivery_edost',
                                tariff_code: this.formData.shipping.deliveryTypeAvailable.tariff.id,
                            };

                            //<----------->
                            //вставка [this.formData.shipping.deliveryOfficeOnOrder] = this.deliveryOptions.on_order_part.offices;
                            //или даже определить this.deliveryOptions.on_order_part.offices;
                            this.deliveryOptions.available_part.offices = this.getDeliveryOfficesByTariffId(this.formData.shipping.deliveryTypeAvailable.tariff_id);
                            [this.formData.shipping.deliveryOfficeAvailable] = this.deliveryOptions.available_part.offices;
                        }

                        if (this.formData.shipping.deliveryTypeOnOrder) {
                            if (!payload.delivery_parameters) payload.delivery_parameters = {};
                            payload.delivery_parameters.on_order_part = {
                                type: 'delivery_edost',
                                tariff_code: this.formData.shipping.deliveryTypeOnOrder.tariff.id,
                            };
                            this.deliveryOptions.on_order_part.offices = this.getDeliveryOfficesByTariffId(this.formData.shipping.deliveryTypeOnOrder.tariff_id);
                            [this.formData.shipping.deliveryTypeOnOrder] = this.deliveryOptions.on_order_part.offices;
                        }

                        if (!this.isDeliveryCalculated){
                            payload.delivery_parameters = {};
                            payload.delivery_parameters.available_part = {
                                type: 'delivery_other',
                                comment: 'не расчитана доставка'
                            };
                        }

                    }
                    try {
                        const result = await apiOrdersCalculate(payload);

                        if (result && result.price_total) {
                            this.total = result.price_total;
                        }

                        if (result && result.price_delivery>=0) {
                            this.shippingPrice = result.price_delivery;
                        }

                        if (result && result.price_products) {
                            this.priceProducts = result.price_products;
                        }

                        this.deliveryTime = 0;
                        this.deliveryTimeShipment = '';
                        // this.deliveryTimeString = '';
                        if (result && result.available_part) {
                            this.inStockCart = result.available_part.cart.items;
                            this.inStockProductsPrice = result.available_part.price_products;
                            //рассчет количества дней для доставки
                            // console.log(this.formData.shipping.deliveryTypeAvailable);
                            // console.log(this.deliveryTime);
                            // this.deliveryTime = this.formData.shipping.deliveryTypeAvailable.delivery_time || 0;

                            this.inStockCart.forEach((item) => {
                                const mappedStocks = reduceStocks(item.product.stocks);
                                const currentStock = mappedStocks.find((stock) => item.quantity_packages < stock.amount_in_packages);
                                if (currentStock) {
                                    if (currentStock.delivery_time_in_days !== 0) {
                                        this.deliveryTime = Math.max(this.deliveryTime, currentStock.delivery_time_in_days);
                                    }
                                }
                            });

                            if( this.formData.shipping.deliveryTypeAvailable &&
                                    this.formData.shipping.deliveryTypeAvailable.delivery_time !== undefined &&
                                    this.formData.shipping.deliveryTypeAvailable.delivery_time !== 0 )
                            {
                                    this.deliveryTimeShipment = this.formData.shipping.deliveryTypeAvailable.delivery_time;
                            }

                            //----
                            if (result.available_part.cart){
                                this.discountAmount = result.available_part.cart.discount_amount;
                                this.discountCode = result.available_part.discount_code ?? '';
                            }
                            //----

                        } else {
                            this.inStockCart = [];
                        }

                        if (result && result.on_order_part) {
                            this.outOfStockCart = result.on_order_part.cart.items;
                            this.outOfStockProductsPrice = result.on_order_part.price_total;
                            this.outOfStockCart.forEach((item) => {
                                const mappedStocks = reduceStocks(item.product.stocks);
                                const currentStock = mappedStocks.find((stock) => item.quantity_packages < stock.amount_in_packages);
                                if (currentStock) {
                                    if (currentStock.delivery_time_in_days !== 0) {
                                        this.deliveryTime = Math.max(this.deliveryTime, currentStock.delivery_time_in_days);
                                    }
                                }
                            });
                            if (this.formData.shipping.pickupAddressId) {
                                this.cartNotificationCity = this.warehouses
                                    .find(warehouse => warehouse.id === this.formData.shipping.pickupAddressId).name;
                            } else {
                                this.cartNotificationCity = this.warehouses[0].name;
                            }
                        } else {
                            this.outOfStockCart = [];
                        }
                    } catch (e) {
                        showErrorMessage({
                            text: 'Не удалось рассчитать заказ',
                        });
                    }
                }

                UIkit.update(this.cartTotalStickyElement, 'update');

                this.calculateLoading = false;

                return null;
            },

            onSubmit() {
                const elUserData = document.getElementById('user_data');
                let payload = {};
                if (!this.formData.name) {
                    showErrorMessage({
                        text: 'Заполните данные получателя',
                    });
                    elUserData && elUserData.scrollIntoView();

                    return;
                }

                if (!this.formData.orderPhone) {
                    showErrorMessage({
                        text: 'Укажите телефон для связи',
                    });
                    elUserData && elUserData.scrollIntoView();
                    return;
                }

                if (this.formData.shipping.type === SHIPPING_TYPES.PICKUP) {
                    if (!this.formData.shipping.pickupAddressId) {
                        showErrorMessage({
                            text: 'Выберите склад',
                        });

                        return;
                    }

                    payload = {
                        ...payload,
                        delivery_destination: {
                            warehouse_id: this.formData.shipping.pickupAddressId,
                        },
                        delivery_parameters: null,
                    };
                } else if (this.formData.shipping.userAddressId) {
                    payload = {
                        ...payload,
                        delivery_destination: {
                            user_address_id: this.formData.shipping.userAddressId,
                        },
                        delivery_parameters: null,
                    };
                } else if (this.addressFormFilled) {
                    payload = {
                        ...payload,
                        delivery_destination: {
                            address: this.formattedAddress,
                        },
                        delivery_parameters: null,
                    };
                }

                if (!this.formData.payment.type) {
                    showErrorMessage({
                        text: 'Выберите способ оплаты',
                    });

                    return;
                }

                // if ( (this.formData.payment.type === PAYMENT_TYPES.ONLINE || this.formData.payment.type === PAYMENT_TYPES.ONLINE_YOO)&& !this.formData.email) {
                if ( !this.formData.email) {
                    showErrorMessage({
                        text: 'Введите email',
                    });
                    elUserData && elUserData.scrollIntoView();
                    return;
                }

                if (this.formData.email && !VALIDATION_RULES.EMAIL.regexp.test(this.formData.email)) {
                    showErrorMessage({
                        text: 'Некорректный email',
                    });

                    return;
                }

                if (!payload.delivery_destination) {
                    showErrorMessage({
                        text: 'Выберите адрес',
                    });

                    return;
                }

                if (this.formData.shipping.type === SHIPPING_TYPES.DELIVERY) {
                    if (!this.deliveryOptions) {
                        showErrorMessage({
                            text: 'Выберите способ доставки',
                        });

                        return;
                    }

                    if (this.formData.shipping.deliveryTypeAvailable) {
                        payload.delivery_parameters = {};

                        payload.delivery_parameters.available_part = {
                            type: 'delivery_edost',
                            tariff_code: this.formData.shipping.deliveryTypeAvailable.tariff.id,
                            comment: (this.formData.shipping.deliveryOfficeAvailable)?
                                JSON.stringify({delivery:this.formData.shipping.deliveryOfficeAvailable}):
                                JSON.stringify({type:this.formData.shipping.deliveryTypeAvailable}),
                        };
                    }

                    if (this.formData.shipping.deliveryTypeOnOrder) {
                        if (!payload.delivery_parameters) payload.delivery_parameters = {};
                        payload.delivery_parameters.on_order_part = {
                            type: 'delivery_edost',
                            tariff_code: this.formData.shipping.deliveryTypeOnOrder.tariff.id,
                            comment: (this.formData.shipping.deliveryOfficeOnOrder)?
                                JSON.stringify({delivery:this.formData.shipping.deliveryOfficeOnOrder}):
                                JSON.stringify({type:this.formData.shipping.deliveryTypeOnOrder}),
                        };
                    }

                    if (!this.isDeliveryCalculated){
                        payload.delivery_parameters = {};
                        payload.delivery_parameters.available_part = {
                            type: 'delivery_other',
                            comment: 'не рассчитана доставка',
                            is_delivery_calculated: false
                        };
                    }
                }
                let customer = this.formData.name;
                if(this.formData.payment && this.formData.payment.account && this.formData.payment.account.inn ){
                    customer = this.formData.payment.account.inn ? ('ИНН: '+this.formData.payment.account.inn+', '):'';
                    customer += this.formData.payment.account.kpp ? ('КПП: '+this.formData.payment.account.kpp+', '):'';
                    customer += this.formData.payment.account.organizationName ? ('Наименование: '+this.formData.payment.account.organizationName+', '):'';
                    customer += this.formData.payment.account.contacts ? ('Контакты: '+this.formData.payment.account.contacts):'';
                }

                payload = {
                    ...payload,
                    payment_method: this.formData.payment.type,
                    customer_email: this.formData.email || null,
                    customer_name: customer,
                    requires_manager_call: this.formData.requiresManagerCall || false,
                    comment: this.formData.comment || null,
                    order_phone: this.formData.orderPhone || '',
                };
                payload.order_phone = payload.order_phone.replace(/\s+/g, '');

                this.submitButtonLoading = true;

                //для метрики, корзина
                let yaProducts = [];
                this.cart.items.forEach( (item) => {
                    yaProducts.push({
                        id: item.product.vendorCode || '',
                        name: item.product.vendorCode+'. '+item.product.name.replace("&nbsp;",'') || '',
                        price: item.priceRetailPerPackage/100 || 0,
                        brand:'Точка Опоры',
                        quantity: item.quantityPackages || 0
                    });
                });

                this.sendForm(
                    () => apiOrdersCreate(payload),
                    async (result) => {
                        this.successFormSending();

                        if (payload.delivery_destination.address) {
                            await this.createUserAddress(payload.delivery_destination.address);
                        }

                        yaEcommerce('purchase', yaProducts, result.order_numbers[0] || '' );
                        carrotquestTrack('Создан заказ', {orderId: result.order_numbers[0] || ''});
                        try{
                            ym(90266543,'reachGoal','create_order');
                        }catch (e) {
                            console.log('Error: '+e.message);
                        }


                        if (result.redirect) {
                            if (result.redirect.success_page_url) {
                                window.location.href = result.redirect.success_page_url;
                            }

                            if (result.redirect.payment_page_url) {
                                window.location.href = result.redirect.payment_page_url;
                            }
                        }
                    },
                    () => {
                        this.submitButtonLoading = false;
                        console.log(this.formData);
                        console.log(payload);
                        showErrorMessage({
                            text: 'Не удалось создать заказ',
                        });
                    }
                );
            },

            setDataFromSuggestion( suggestion ){
                if (suggestion.data && suggestion.data !== undefined){
                    //timeout - ы нужны, тк при изменении города/улицы происходит обнуление другиз полей
                    setTimeout(() => {
                        this.formData.shipping.userAddress.city = suggestion.data.city ?? (suggestion.data.settlement ?? null);
                    }, 300);
                    setTimeout(() => {
                        this.formData.shipping.userAddress.street = suggestion.data.street_with_type ?? (suggestion.data.settlement_with_type ?? null);
                    }, 300);
                    setTimeout(() => {
                        this.formData.shipping.userAddress.postalCode = suggestion.data.postal_code ?? null;
                    }, 300);
                    setTimeout(() => {
                        this.formData.shipping.userAddress.building = suggestion.data.house ?? null;
                    }, 300);
                    setTimeout(() => {
                        this.formData.shipping.userAddress.flat = suggestion.data.flat ?? null;
                    }, 300);
                }
            },
            //----------Discount---------
            applyDiscount(){
                console.log('discount');
                console.log(this.discountCode);
                // console.log(this.cart);
                this.sendForm(
                    () =>
                        apiApplyDiscountToCart({
                            code: this.discountCode,
                        }),
                    (result) => {
                        if(result.data === 'success'){
                            this.calculateOrder();
                        }else{
                            showErrorMessage({
                                text: result.data,
                            });
                        }
                    },
                    () => {
                        showErrorMessage({
                            text: 'Ошибка',
                        });
                    },
                    {
                        useValidation: false,
                    }
                );
            },
            releaseDiscount(){
                this.sendForm(
                    () =>
                        apiReleaseDiscountToCart({}),
                    (result) => {
                        this.calculateOrder();
                    },
                    () => {
                        showErrorMessage({
                            text: 'Ошибка',
                        });
                    },
                );
            }
            //---------------------------
        },
        watch: {
            cart() {
                if (this.formData.shipping.pickupAddressId !== null) {
                    this.calculateOrder();
                } else {
                    this.setCartPrice();
                }
            },
            'formData.shipping.userAddressId': function (val) {
                this.getDeliveryOptions();

                if (val) {
                    this.isAddingAddress = false;
                }
            },
            'formData.shipping.type': function (val) {
                if (this.formData.shipping.userAddressId !== null && val === SHIPPING_TYPES.DELIVERY) {
                    this.getDeliveryOptions();
                }

                if (this.formData.shipping.pickupAddressId !== null && val === SHIPPING_TYPES.PICKUP) {
                    this.removeDeliveryOptions();
                    this.calculateOrder();
                    this.isDeliveryCalculated = true;
                }
            },
            'formData.shipping.deliveryTypeAvailable': function (val, oldVal) {
                if (oldVal) {
                    this.calculateOrder();
                }
            },
            'formData.shipping.deliveryTypeOnOrder': function (val, oldVal) {
                if (oldVal) {
                    this.calculateOrder();
                }
            },
            deliveryTime() {
                if (this.deliveryTime > 0) {
                    const nowDate = new Date();
                    nowDate.setDate(nowDate.getDate() + this.deliveryTime);

                    this.deliveryTimeString = mapDate(nowDate, { day: "numeric", month: "long" });
                }
            },
            'formData.shipping.userAddress': {
                deep: true,
                handler: debounce(function () {
                    this.getDeliveryOptions();
                }, 400),
            },
            'formData.payment.type': function (val) {
                if (val === PAYMENT_TYPES.ACCOUNT_NUMBER) {
                    this.formData.payment = {
                        ...this.formData.payment,
                        account: {
                            organizationName: this.roleData.organizationName,
                            inn: this.roleData.inn,
                            kpp: this.roleData.kpp,
                            rs: this.roleData.rs,
                            name: this.roleData.name,
                            phone: this.roleData.phone,
                        },
                    };
                }

                // Update cart-total sticky bottom max position
                UIkit.update(this.cartTotalStickyElement, 'update');
            },
            'formData.shipping.userAddress.country': function (val, oldVal) {
                if (oldVal) {
                    this.formData.shipping.userAddress.city = null;
                    this.formData.shipping.userAddress.street = null;
                    this.formData.shipping.userAddress.postalCode = '';
                    this.formData.shipping.userAddress.building = '';
                    this.formData.shipping.userAddress.flat = '';
                }
            },
            'formData.shipping.userAddress.city': function (val, oldVal) {
                if (oldVal) {
                    this.formData.shipping.userAddress.street = null;
                    this.formData.shipping.userAddress.postalCode = '';
                    this.formData.shipping.userAddress.building = '';
                    this.formData.shipping.userAddress.flat = '';
                }
            },
            'formData.shipping.userAddress.street': function (val, oldVal) {
                if (oldVal) {
                    this.formData.shipping.userAddress.postalCode = '';
                    this.formData.shipping.userAddress.building = '';
                    this.formData.shipping.userAddress.flat = '';
                }
            },
            'formData.shipping.pickupAddressId': function () {
                if (this.formData.shipping.pickupAddressId !== null) {
                    this.formData.shipping.deliveryTypeAvailable = null;
                    this.calculateOrder();
                }
            },
            'city': function (val, oldVal) {
                // if (oldVal) {
                this.setDataFromSuggestion(globalSuggestion);
                // }
            },
        },
    });
