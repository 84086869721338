var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "ProductStatus",
      class: {
        "ProductStatus--available": _vm.productStock.status.id === "available",
        "ProductStatus--onOrder": _vm.productStock.status.id === "on_order",
        "ProductStatus--unavailable":
          _vm.productStock.status.id === "unavailable",
      },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.productStock.status.name))]),
      _vm.productStock.delivery_time_in_days > 0
        ? _c("span", [
            _vm._v(
              ", в течение " +
                _vm._s(_vm.productStock.delivery_time_in_days) +
                " " +
                _vm._s(_vm.productStock.day_format)
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.productStock.status.id === "unavailable"
        ? _c(
            "button",
            {
              staticClass: "Button Button--lightGray",
              attrs: { "data-uk-toggle": "target: #part-request-modal" },
            },
            [_vm._v("Сообщить о поступлении")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }